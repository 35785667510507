<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="tabs tabs-boxed mb-2 bg-base-100 !flex">
                <div class="flex justify-between w-full">
                    <div>
                        <a v-for="{ value, text } in options" :key="value"
                           :class="{ 'tab': true, 'tab-active': sortValue === value }">
                            <router-link :to="{ 'path': '/playlists/' + value }">
                                {{ text }}
                            </router-link>
                        </a>
                    </div>
                    <div>
                        <button class="btn btn-active btn-sm" @click="handleAiPlayList">智能歌单</button>
                        <dialog ref="ai-list" class="modal">
                            <div class="modal-box w-11/12 max-w-5xl">
                                <form method="dialog">
                                    <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                                </form>
                                <h3 class="font-bold text-lg mb-4">创建智能播放列表</h3>
                                <div class="mb-2">
                                    <span>列表名称：</span>
                                    <input type="text" placeholder="请输入播放列表名称" class="input input-bordered input-sm w-full max-w-xs" v-model="playlistName" />
                                </div>
                                <div v-for="(item, index) in conditions" :key="'a' + index">
                                    <div class="flex items-center">
                                        <div v-if="index === 0">满足条件</div>
                                        <div v-else>
                                            <div class="btn btn-sm">或</div>
                                            满足条件
                                        </div>
                                        <div class="ml-2 flex">
                                            <button class="btn btn-square btn-outline btn-sm mr-1" @click="addItem">+</button>
                                            <button class="btn btn-square btn-outline btn-sm" @click="removeItem(index)" v-if="conditions.length > 1">-</button>
                                        </div>
                                    </div>
                                    <div class="flex mt-2 ml-6 items-center" v-for="(sub, subIndex) in item" :key="'s' + index + 'a' + subIndex">
                                        <div v-if="subIndex >= 1" class="mr-1 btn btn-sm">且</div>
                                        <select class="select select-bordered select-sm w-full max-w-xs mr-1" v-model="sub.key">
                                            <option value="favourite-track">标记为喜爱的歌曲</option>
                                            <option value="favourite-album">标记为喜爱的专辑</option>
                                            <option value="favourite-artist">标记为喜爱的艺术家</option>
                                            <option value="title">标题</option>
                                            <option value="album">专辑</option>
                                            <option value="artist">艺术家</option>
                                            <option value="genre">流派</option>
                                            <option value="comment">描述</option>
                                            <option value="lyrics">歌词</option>
                                            <option value="album-img">专辑图片</option>
                                            <option value="artist-img">艺术家图片</option>
                                            <option value="play-count">播放次数</option>
                                            <option value="bit-rate">比特率</option>
                                            <option value="size">大小</option>
                                            <option value="year">年份</option>
                                            <option value="duration">时长</option>
                                            <option value="accessed-date">距离最近播放</option>
                                            <option value="created-at">距离最近添加</option>
                                        </select>
                                        <select class="select select-bordered select-sm w-full max-w-xs mr-1"
                                                v-if="boolKeyList.indexOf(sub.key) !== -1"
                                                v-model="sub.operate">
                                            <option value="is">是</option>
                                            <option value="not">不是</option>
                                        </select>
                                        <select class="select select-bordered select-sm w-full max-w-xs mr-1"
                                                v-else-if="numberKeyList.indexOf(sub.key) !== -1"
                                                v-model="sub.operate">
                                            <option value="gte">大于</option>
                                            <option value="lte">小于</option>
                                            <option value="is">是</option>
                                            <option value="not">不是</option>
                                        </select>
                                        <select class="select select-bordered select-sm w-full max-w-xs mr-1"
                                                v-else
                                                v-model="sub.operate">
                                            <option value="contain">包含</option>
                                            <option value="not_contain">不包含</option>
                                            <option value="is">是</option>
                                            <option value="not">不是</option>
                                            <option value="startwith">开头是</option>
                                            <option value="endwith">结尾是</option>
                                        </select>
                                        <select class="select select-bordered select-sm w-full max-w-xs"
                                                v-if="boolKeyList.indexOf(sub.key) !== -1"
                                                v-model="sub.value">
                                            <option value="">True</option>
                                        </select>
                                        <div class="w-full max-w-xs flex items-center"
                                             v-else-if="numberKeyList.indexOf(sub.key) !== -1">
                                            <input type="number" placeholder="请输入" class="input input-bordered input-sm w-full max-w-xs" v-model="sub.value" />
                                            <div class="ml-2" v-if="sub.key === 'bit-rate'">kps</div>
                                            <div class="ml-2" v-else-if="sub.key === 'size'">MB</div>
                                            <div class="ml-2" v-else-if="sub.key === 'play-count'">次</div>
                                            <div class="ml-2" v-else-if="sub.key === 'year'">年</div>
                                            <div class="ml-2" v-else-if="sub.key === 'duration'">秒</div>
                                            <div class="ml-2" v-else-if="sub.key === 'accessed-date'">天</div>
                                            <div class="ml-2" v-else-if="sub.key === 'created-at'">天</div>
                                            <div v-else></div>
                                        </div>
                                        <div class="w-full max-w-xs" v-else>
                                            <input type="text" placeholder="请输入" class="input input-bordered input-sm w-full max-w-xs" v-model="sub.value" />
                                        </div>
                                        <div class="ml-2 flex">
                                            <button class="btn btn-square btn-outline btn-sm mr-1" @click="addSubItem(index)">+</button>
                                            <button class="btn btn-square btn-outline btn-sm" @click="removeSubItem(index, subIndex)" v-if="item.length > 1">-</button>
                                        </div>
                                    </div>
                                    <div class="divider" v-if="conditions.length > 1 && conditions.length !== index + 1">OR</div>
                                </div>
                                <div class="flex mt-4 items-center">
                                    <div class="w-8 mr-1">最多</div>
                                    <input type="text" placeholder="请输入" class="input input-bordered input-sm w-16 max-w-xs mr-1" v-model="limit" />
                                    <select class="select select-bordered select-sm w-16 max-w-xs mr-2" v-model="unit">
                                        <option value="item">项</option>
                                    </select>
                                    <div class="w-16 mr-1">选择标准</div>
                                    <select class="select select-bordered select-sm w-32 max-w-xs" v-model="selectStandard">
                                        <option value="?">随机</option>
                                        <option value="-accessed_date">最近播放</option>
                                        <option value="accessed_date">最早播放</option>
                                        <option value="-plays_count">最常播放</option>
                                        <option value="plays_count">最少播放</option>
                                        <option value="-created_at">最近添加</option>
                                        <option value="created_at">最早添加</option>
                                    </select>
                                </div>
                                <div class="modal-action">
                                    <button class="btn btn-sm btn-neutral" @click="handleCreate">保存</button>
                                    <form method="dialog">
                                        <!-- if there is a button in form, it will close the modal -->
                                        <button class="btn btn-sm">取消</button>
                                    </form>
                                </div>
                            </div>
                        </dialog>
                    </div>
                </div>

            </div>
        </div>
        <Tiles v-if="playlist.length > 0">
            <Tile
                v-for="item in playlist" :key="item.id"
                :image="item.image"
                :to="{ name: 'playlist', params: { id: item.id } }"
                :title="item.name">
                <template #text>
                    <router-link :to="{ name: 'playlist', params: { id: item.id } }">
                        <strong>{{ item.trackCount }}</strong> 歌曲
                    </router-link>
                </template>
                <template #play>
                    <div class="absolute top-[43%] z-10 left-[39%] play-icon cursor-pointer" @click="playNow(item.id)">
                        <svg class="fill-purple-800 w-12 h-12 hover:fill-purple-600" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2415" width="32" height="32"><path d="M512 1024A512 512 0 1 1 512 0a512 512 0 0 1 0 1024zM383.232 287.616v448l384-223.104-384-224.896z" p-id="2416"></path></svg>
                    </div>
                </template>
            </Tile>
        </Tiles>
    </div>
</template>
<script>
    import Tiles from '@/components/library/Tiles'
    import Tile from '@/components/library/Tile'
    export default {
        components: {
            Tiles, Tile
        },
        props: {
        },
        data() {
            return {
                options: [
                    {text: '最近添加', value: 'recently-added'},
                    {text: 'A-Z排序', value: 'a-z'}
                ],
                playlist: [],
                playlistName: '',
                limit: 25,
                unit: 'item',
                selectStandard: '?',
                showAddModal: false,
                conditions: [[
                    {'key': 'favourite-track', 'operate': 'is', 'value': ''}
                ]],
                boolKeyList: ['favourite-track', 'favourite-album', 'favourite-artist', 'album-img', 'artist-img'],
                numberKeyList: ['play-count', 'sample-rate', 'bit-rate', 'size', 'year', 'duration', 'accessed-date',
                'created-at']
            }
        },
        computed: {
            sortValue: {
                get() {
                    return this.$route.params.sort
                }
            }
        },
        watch: {
            sortValue: {
                async handler() {
                    this.playlist = await this.$api.subsonic.getPlaylists(this.sortValue)
                }
            }
        },
        async created() {
            this.playlist = await this.$api.subsonic.getPlaylists(this.sortValue)
        },
        methods: {
            async playNow(id) {
                const playlist = await this.$api.subsonic.getPlaylist(id)
                return this.$store.dispatch('playTrackList', {
                    tracks: playlist.tracks
                })
            },
            handleAiPlayList() {
                this.$refs["ai-list"].showModal()
            },
            addSubItem(index) {
                this.conditions[index].push({'key': '', 'operate': '', 'value': ''})
            },
            removeSubItem(index, subIndex) {
                if (this.conditions[index].length > 1) {
                    this.conditions[index].splice(subIndex, 1)
                }
            },
            addItem() {
                this.conditions.push([{'key': '', 'operate': '', 'value': ''}])
            },
            removeItem(index) {
                this.conditions.splice(index, 1)
            },
            handleCreate(){
                if (!this.playlistName) {
                    this.$cwMessage('播放列表名称不能为空！', 'error')
                    return
                }
                console.log(this.conditions)
                this.$api.subsonic.createPlaylistAi(this.playlistName,
                    JSON.stringify(this.conditions),this.limit, this.selectStandard).then((res) => {
                    this.$cwMessage('添加成功', 'success')
                    this.$refs["ai-list"].close()
                    this.$api.subsonic.getPlaylists(this.sortValue).then((res) => {
                        this.playlist = res
                    })

                })
            }
        }
    }
</script>

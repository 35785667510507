import { render, staticRenderFns } from "./PlayingDetails.vue?vue&type=template&id=6ceb0a97&scoped=true"
import script from "./PlayingDetails.vue?vue&type=script&lang=js"
export * from "./PlayingDetails.vue?vue&type=script&lang=js"
import style0 from "./PlayingDetails.vue?vue&type=style&index=0&id=6ceb0a97&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ceb0a97",
  null
  
)

export default component.exports
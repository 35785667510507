<template>
    <div>
        <div
            style="display: flex;flex-direction: column;margin-top: 20px;flex: 1;margin-right: 20px;margin-left: 20px;">
            <div v-if="fileId3List.length === 0">
                <span style="margin-left: 30%;margin-top: 30%;"></span>
            </div>
            <div v-else>
                <div class="flex justify-end">
                    <div class="text-gray-400">
                        <div>总数：{{fileId3List.length}}</div>
                    </div>
                </div>
                <div class="overflow-x-auto">
                    <table class="table">
                        <!-- head -->
                        <thead>
                            <tr>
                                <th v-for="(item, index) in showFieldsID3" :key="'id3' + index">
                                    <div @click="sortBy('artwork')" class="cursor-pointer" v-if="item === 'album_img'">
                                        <div class="flex">
                                            <div>封面</div>
                                            <div v-if="nowSortedKey === 'artwork'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('title')" class="cursor-pointer" v-else-if="item === 'title'">
                                        <div class="flex">
                                            <div>标题</div>
                                            <div v-if="nowSortedKey === 'title'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('filename')" class="cursor-pointer" v-else-if="item === 'filename'">
                                        <div class="flex">
                                            <div>文件名</div>
                                            <div v-if="nowSortedKey === 'filename'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('artist')" class="cursor-pointer" v-else-if="item === 'artist'">
                                        <div class="flex">
                                            <div>艺术家</div>
                                            <div v-if="nowSortedKey === 'artist'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('album')" class="cursor-pointer" v-else-if="item === 'album'">
                                        <div class="flex">
                                            <div>专辑</div>
                                            <div v-if="nowSortedKey === 'album'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('lyrics')" class="cursor-pointer" v-else-if="item === 'lyrics'">
                                        <div class="flex">
                                            <div>歌词</div>
                                            <div v-if="nowSortedKey === 'lyrics'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('lyrics_file')" class="cursor-pointer" v-else-if="item === 'lyrics_file'">
                                        <div class="flex">
                                            <div>LRC</div>
                                            <div v-if="nowSortedKey === 'lyrics_file'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('tracknumber')" class="cursor-pointer" v-else-if="item === 'tracknumber'">
                                        <div class="flex">
                                            <div>音轨号</div>
                                            <div v-if="nowSortedKey === 'tracknumber'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('discnumber')" class="cursor-pointer" v-else-if="item === 'discnumber'">
                                        <div class="flex">
                                            <div>CD号</div>
                                            <div v-if="nowSortedKey === 'discnumber'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('year')" class="cursor-pointer" v-else-if="item === 'year'">
                                        <div class="flex">
                                            <div>年份</div>
                                            <div v-if="nowSortedKey === 'year'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('albumartist')" class="cursor-pointer" v-else-if="item === 'albumartist'">
                                        <div class="flex">
                                            <div>专辑艺术家</div>
                                            <div v-if="nowSortedKey === 'albumartist'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('album_type')" class="cursor-pointer" v-else-if="item === 'album_type'">
                                        <div class="flex">
                                            <div>专辑类型</div>
                                            <div v-if="nowSortedKey === 'album_type'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('genre')" class="cursor-pointer" v-else-if="item === 'genre'">
                                        <div class="flex">
                                            <div>风格</div>
                                            <div v-if="nowSortedKey === 'genre'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('comment')" class="cursor-pointer" v-else-if="item === 'comment'">
                                        <div class="flex">
                                            <div>描述</div>
                                            <div v-if="nowSortedKey === 'comment'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('description')" class="cursor-pointer" v-else-if="item === 'description'">
                                        <div class="flex">
                                            <div>描述2</div>
                                            <div v-if="nowSortedKey === 'description'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('totaltracks')" class="cursor-pointer" v-else-if="item === 'totaltracks'">
                                        <div class="flex">
                                            <div>总音轨数</div>
                                            <div v-if="nowSortedKey === 'totaltracks'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('totaldiscs')" class="cursor-pointer" v-else-if="item === 'totaldiscs'">
                                        <div class="flex">
                                            <div>总CD数</div>
                                            <div v-if="nowSortedKey === 'totaldiscs'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('duration')" class="cursor-pointer" v-else-if="item === 'duration'">
                                        <div class="flex">
                                            <div>时长</div>
                                            <div v-if="nowSortedKey === 'duration'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('size')" class="cursor-pointer" v-else-if="item === 'size'">
                                        <div class="flex">
                                            <div>文件大小</div>
                                            <div v-if="nowSortedKey === 'size'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('bit_rate')" class="cursor-pointer" v-else-if="item === 'bit_rate'">
                                        <div class="flex">
                                            <div>比特率</div>
                                            <div v-if="nowSortedKey === 'bit_rate'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('sample_rate')" class="cursor-pointer" v-else-if="item === 'sample_rate'">
                                        <div class="flex">
                                            <div>采样率</div>
                                            <div v-if="nowSortedKey === 'sample_rate'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('channels')" class="cursor-pointer" v-else-if="item === 'channels'">
                                        <div class="flex">
                                            <div>声道</div>
                                            <div v-if="nowSortedKey === 'channels'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('bit_depth')" class="cursor-pointer" v-else-if="item === 'bit_depth'">
                                        <div class="flex">
                                            <div>位深</div>
                                            <div v-if="nowSortedKey === 'bit_depth'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="sortBy('language')" class="cursor-pointer" v-else-if="item === 'language'">
                                        <div class="flex">
                                            <div>语言</div>
                                            <div v-if="nowSortedKey === 'language'">
                                                <div v-if="nowSortedOrder === 'forward'">
                                                    <svg t="1705023479708" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3094" width="200" height="200"><path d="M704 128h0.853333l1.664 0.085333a42.922667 42.922667 0 0 1 27.648 12.373334l-3.84-3.413334 0.341334 0.341334 3.498666 3.114666 128 128 3.541334 4.010667a42.666667 42.666667 0 0 1-63.872 56.32L746.666667 273.706667V832l-0.298667 4.992A42.666667 42.666667 0 0 1 661.333333 832V273.706667l-55.168 55.125333a42.666667 42.666667 0 0 1-63.872-56.32l3.541334-4.010667 128-128 3.498666-3.114666 0.341334-0.298667-3.84 3.413333a43.008 43.008 0 0 1 23.850666-12.032A42.538667 42.538667 0 0 1 704 128l-3.84 0.170667 1.322667-0.085334 1.706666-0.042666zM554.666667 725.333333a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 810.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 725.333333h341.333334z m0-256a42.666667 42.666667 0 0 1 4.992 85.034667L554.666667 554.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 469.333333h341.333334z m-128-256a42.666667 42.666667 0 0 1 4.992 85.034667L426.666667 298.666667H213.333333a42.666667 42.666667 0 0 1-4.992-85.034667L213.333333 213.333333h213.333334z" p-id="3095"></path></svg>
                                                </div>
                                                <div v-else-if="nowSortedOrder === 'reverse'">
                                                    <svg t="1705023862289" class="icon fill-purple-400 w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3237" width="200" height="200"><path d="M704 128a42.666667 42.666667 0 0 1 42.368 37.674667L746.666667 170.666667v558.293333l55.168-55.125333a42.666667 42.666667 0 0 1 63.872 56.32l-3.541334 4.010666-128 128a42.922667 42.922667 0 0 1-3.84 3.413334l3.84-3.413334a43.008 43.008 0 0 1-22.613333 11.818667l-1.194667 0.213333c-0.853333 0.128-1.792 0.256-2.688 0.298667l-1.152 0.085333-1.066666 0.042667-1.450667 0.042667-1.408-0.085334h-1.109333c-0.426667 0-0.768 0-1.152-0.085333l3.669333 0.170667a43.008 43.008 0 0 1-26.325333-9.088 43.349333 43.349333 0 0 1-3.84-3.413334l-128-128-3.541334-4.010666a42.666667 42.666667 0 0 1 63.872-56.32l55.168 55.125333V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667zM426.666667 704l4.992 0.298667A42.666667 42.666667 0 0 1 426.666667 789.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 704h213.333334z m128-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 533.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 448h341.333334z m0-256l4.992 0.298667A42.666667 42.666667 0 0 1 554.666667 277.333333H213.333333l-4.992-0.298666A42.666667 42.666667 0 0 1 213.333333 192h341.333334z"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in fileId3List" :key="index">
                                <td v-for="(item2, index2) in showFieldsID3" :key="index + '-' + index2">
                                    <div class="flex items-center gap-3" v-if="item2 === 'album_img'">
                                        <div class="avatar">
                                            <div class="mask mask-squircle w-16 h-16 cursor-pointer">
                                                <img :src="item.artwork" alt="" v-if="item.artwork" />
                                                <div v-else></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="handleEdit2(item)" class="cursor-pointer hover:underline max-w-1/3" v-else-if="item2 === 'filename'">
                                        {{item.filename}}
                                    </div>
                                    <div v-else-if="item2 === 'title'" class="max-w-1/3">{{item.title}}</div>
                                    <div v-else-if="item2 === 'artist'">{{item.artist}}</div>
                                    <div v-else-if="item2 === 'album'">{{item.album }}</div>
                                    <div v-else-if="item2 === 'lyrics'">
                                        <div>
                                            <div class="badge badge-outline" v-if="item.lyrics">有</div>
                                            <div class="badge badge-primary badge-outline cursor-pointer" v-else @click="toggleLock(item)">无</div>
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'lyrics_file'">
                                        <div>
                                            <div class="badge badge-outline" v-if="item.lyrics_file">有</div>
                                            <div class="badge badge-primary badge-outline" v-else>无</div>
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'tracknumber'">
                                        <div v-if="item.tracknumber" class="flex">
                                            <div>
                                                {{item.tracknumber}}
                                            </div>
                                            <div>/</div>
                                            <div>
                                                {{item.totaltracks}}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'discnumber'">
                                        <div v-if="item.discnumber" class="flex">
                                            <div>
                                                {{item.discnumber}}
                                            </div>
                                            <div>/</div>
                                            <div>
                                                {{item.totaldiscs}}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'year'">
                                        <div>
                                            {{ item.year }}
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'albumartist'">
                                        <div>
                                            {{ item.albumartist }}
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'album_type'">
                                        <div>
                                            {{ item.album_type }}
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'genre'">
                                        <div>
                                            {{ item.genre }}
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'comment'">
                                        <div>
                                            {{ item.comment }}
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'description'">
                                        <div>
                                            {{ item.description }}
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'totaltracks'">
                                        <div>
                                            {{ item.totaltracks }}
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'totaldiscs'">
                                        <div>
                                            {{ item.totaldiscs }}
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'duration'">
                                        <div>
                                            {{ item.duration }}
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'size'">
                                        <div>
                                            {{ item.size }}
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'bit_rate'">
                                        <div>
                                            {{ item.bit_rate }}
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'sample_rate'">
                                        <div>
                                            {{ item.sample_rate }}
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'channels'">
                                        <div>
                                            {{ item.channels }}
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'bit_depth'">
                                        <div>
                                            {{ item.bit_depth }}
                                        </div>
                                    </div>
                                    <div v-else-if="item2 === 'language'">
                                        <div>
                                            {{ item.language }}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'multi-meta-data',
        props: {
            fileId3List: {type: Array, required: true}
        },
        data() {
            return {
                showFieldsID3: localStorage.getItem('showFieldsID3') ? JSON.parse(localStorage.getItem('showFieldsID3')) : ['album_img', 'filename', 'title', 'artist', 'album', 'albumartist', 'lyrics', 'lyrics_file', 'year', 'genre'],
                sortedField: localStorage.getItem('sortedField') ? JSON.parse(localStorage.getItem('sortedField')) : [],
                sortedListOrder: ['forward', 'reverse'],
                nowSortedOrder: 'none',
                nowSortedKey: '',
                musicInfo: {},
                isShowEdit2: false,
                drawerMode: 'drawer'
            }
        },
        methods: {
            sortBy(key) {
                this.nowSortedKey = key
                this.nowSortedOrder = this.getNextValue()

                if (this.nowSortedOrder === 'reverse') {
                    this.fileId3List.sort((a, b) => {
                        if (a[key] > b[key]) return -1
                        if (a[key] < b[key]) return 1
                        return 0
                    })
                } else if (this.nowSortedOrder === 'forward') {
                    this.fileId3List.sort((a, b) => {
                        if (a[key] > b[key]) return 1
                        if (a[key] < b[key]) return -1
                        return 0
                    })
                } else if (this.nowSortedOrder === 'none') {
                    console.log()
                }
            },
            getNextValue() {
                let currentIndex = this.sortedListOrder.indexOf(this.nowSortedOrder)
                currentIndex++
                if (currentIndex >= this.sortedListOrder.length) {
                    currentIndex = 0
                }
                return this.sortedListOrder[currentIndex]
            },
            handleEdit2(item) {
                this.$parent.handleEdit2(item)
            },
            toggleLock(item) {
                this.$parent.handleEdit2(item)
                this.$parent.toggleLock(this.drawerMode)
            }
        }
    }
</script>

<style scoped>

</style>

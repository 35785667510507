<template>
    <div class="overflow-y-auto h-screen-header" ref="viewhome">
        <div class="tabs tabs-boxed mb-2 bg-base-100 !flex">
            <div class="flex justify-between w-full">
                <div>
                    <a v-for="{ value, text } in options" :key="value"
                        :class="{ 'tab': true, 'tab-active': sortValue === value }">
                        <router-link :to="{ 'path': '/albums/' + value }">
                            {{ text }}
                        </router-link>
                    </a>
                </div>
                <div class="flex justify-center items-center">
                    <a :class="{ 'tab': true, 'tab-active': showMode === 'grid' }" @click="showMode = 'grid'">
                        <svg class="icon w-5 h-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M170.666667 85.333333a85.333333 85.333333 0 0 0-85.333334 85.333334v213.333333a85.333333 85.333333 0 0 0 85.333334 85.333333h213.333333a85.333333 85.333333 0 0 0 85.333333-85.333333V170.666667a85.333333 85.333333 0 0 0-85.333333-85.333334H170.666667zM170.666667 554.666667a85.333333 85.333333 0 0 0-85.333334 85.333333v213.333333a85.333333 85.333333 0 0 0 85.333334 85.333334h213.333333a85.333333 85.333333 0 0 0 85.333333-85.333334v-213.333333a85.333333 85.333333 0 0 0-85.333333-85.333333H170.666667zM640 85.333333a85.333333 85.333333 0 0 0-85.333333 85.333334v213.333333a85.333333 85.333333 0 0 0 85.333333 85.333333h213.333333a85.333333 85.333333 0 0 0 85.333334-85.333333V170.666667a85.333333 85.333333 0 0 0-85.333334-85.333334h-213.333333zM640 554.666667a85.333333 85.333333 0 0 0-85.333333 85.333333v213.333333a85.333333 85.333333 0 0 0 85.333333 85.333334h213.333333a85.333333 85.333333 0 0 0 85.333334-85.333334v-213.333333a85.333333 85.333333 0 0 0-85.333334-85.333333h-213.333333z"></path></svg>
                    </a>
                    <a :class="{ 'tab': true, 'tab-active': showMode === 'list' }" @click="showMode = 'list'">
                        <svg class="icon w-5 h-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M134.976 853.312H89.6c-26.56 0-46.912-20.928-46.912-48.256 0-27.392 20.352-48.32 46.912-48.32h45.376c26.624 0 46.912 20.928 46.912 48.32 0 27.328-20.288 48.256-46.912 48.256zM134.976 560.32H89.6C63.04 560.32 42.688 539.392 42.688 512s20.352-48.32 46.912-48.32h45.376c26.624 0 46.912 20.928 46.912 48.32s-20.288 48.32-46.912 48.32zM134.976 267.264H89.6c-26.56 0-46.912-20.928-46.912-48.32 0-27.328 20.352-48.256 46.912-48.256h45.376c26.624 0 46.912 20.928 46.912 48.256 0 27.392-20.288 48.32-46.912 48.32zM311.744 853.312c-26.56 0-46.912-20.928-46.912-48.256 0-27.392 20.352-48.32 46.912-48.32h622.72c26.56 0 46.848 20.928 46.848 48.32 0 27.328-20.288 48.256-46.912 48.256H311.744c1.6 0 1.6 0 0 0zM311.744 560.32c-26.56 0-46.912-20.928-46.912-48.32s20.352-48.32 46.912-48.32h622.72c26.56 0 46.848 20.928 46.848 48.32s-20.288 48.32-46.912 48.32H311.744c1.6 0 1.6 0 0 0zM311.744 267.264c-26.56 0-46.912-20.928-46.912-48.32 0-27.328 20.352-48.256 46.912-48.256h622.72c26.56 0 46.848 20.928 46.848 48.256 0 27.392-20.288 48.32-46.912 48.32H311.744c1.6 0 1.6 0 0 0z"></path></svg>
                    </a>
                </div>
            </div>
        </div>
        <AlbumList :items="albums" v-if="showMode === 'grid'" />
        <AlbumItems :items="albums" v-if="showMode === 'list'" />
        <div class="flex justify-center" v-if="loading">
            <span class="loading loading-infinity loading-lg"></span>
        </div>
    </div>
</template>
<script>
    import AlbumList from './AlbumList'
    import AlbumItems from './AlbumItems'
    export default {
        components: {
            AlbumList, AlbumItems
        },
        props: {},
        data() {
            return {
                albums: [],
                loading: false,
                offset: 0,
                hasMore: true,
                showMode: 'grid'
            }
        },
        computed: {
            options() {
                return [
                    {text: '最近添加', value: 'recently-added'},
                    {text: '最近播放', value: 'recently-played'},
                    {text: '最多播放', value: 'most-played'},
                    {text: 'A-Z排序', value: 'a-z'},
                    {text: '随机播放', value: 'random'}
                ]
            },
            sortValue: {
                get() {
                    return this.$route.params.sort
                }
            }
        },
        watch: {
            sortValue: {
                handler() {
                    console.log('sortValue changed')
                    this.albums = []
                    this.offset = 0
                    this.hasMore = true
                    this.loadMore()
                }
            }
        },
        mounted() {
            // 事件监听
            this.$refs.viewhome.addEventListener('scroll', this.listenBottomOut)
            window.viewhome = this.$refs.viewhome
        },
        destroyed() {
            // 离开页面取消监听
            window.viewhome.removeEventListener('scroll', this.listenBottomOut, false)
        },
        created() {
            this.loadMore()
        },
        methods: {
            loadMore() {
                this.loading = true
                return this.$api.subsonic.getAlbums(this.sortValue, 30, this.offset).then(albums => {
                    this.albums.push(...albums)
                    this.offset += albums.length
                    this.hasMore = albums.length > 0
                    this.loading = false
                })
            },
            listenBottomOut() {
                if (this.hasMore && !this.loading) {
                    const scrollTop = window.viewhome.scrollTop || document.body.scrollTop
                    const scrollHeight = window.viewhome.scrollHeight || document.body.scrollHeight
                    const clientHeight = window.viewhome.clientHeight || document.body.clientHeight
                    if (scrollTop + clientHeight >= scrollHeight - 50) {
                        this.loadMore()
                    }
                }
            }
        }
    }
</script>
